import { CellProps } from 'react-table';
import { AVAILABLE_FLAVORS } from 'provisioning/opensearch';
import { ResourceTableData } from 'types/types';

export const ESVersionCell = ({ row }: CellProps<ResourceTableData>) => {
  if (row.original?.openSearchInfo?.serverless) {
    return 'Serverless';
  }

  let versionString = 'No Detected Version';
  // get a display string for the detected version
  const value = row.original?.openSearchInfo?.esVersion;
  if (value && value.flavor && value.version) {
    const versionNumber = value.version.slice(0, -2) + '.x';
    versionString = `${
      AVAILABLE_FLAVORS.find((f) => f.value === value.flavor)?.label || value.flavor
    } ${versionNumber}`;
  }

  return versionString;
};
