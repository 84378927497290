import React, { useEffect } from 'react';
import { useStyles, Icon, Button, Input } from '@grafana/ui';
import { css } from '@emotion/css';
import { GrafanaTheme } from '@grafana/data';
import { useCheckboxes } from '../../hooks';
import { ResourceTableData } from 'types/types';
import {
  useTable,
  useRowState,
  Column,
  useRowSelect,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';

export interface Props {
  columns: Array<Column<ResourceTableData>>;
  data: ResourceTableData[];
  onChange: (state: any) => void;
}

export const ResourceTable = ({ columns, data, onChange }: Props) => {
  const styles = useStyles(getStyles);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    pageCount,
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    useRowState,
    usePagination,
    useRowSelect,
    useCheckboxes
  );

  useEffect(() => {
    onChange(state);
  }, [state, onChange]);

  const { pageIndex, globalFilter } = state;

  return (
    <div className={styles.container}>
      <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...headerGroupProps}>
                {headerGroup.headers.map((header, i) => {
                  const { key, ...headerProps } = header.getHeaderProps(header.getSortByToggleProps());
                  return (
                    <th
                      key={key}
                      {...headerProps}
                      //@ts-ignore
                      className={styles[header.className]}
                      style={{ width: header.width }}
                    >
                      {header.render('Header')}
                      {header.isSorted && <Icon name={header.isSortedDesc ? 'arrow-down' : 'arrow-up'} />}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps();
            return (
              <tr key={key} {...rowProps}>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps();
                  return (
                    <td
                      key={key}
                      {...cellProps}
                      style={{ width: cell.column.width }}
                      //@ts-ignore
                      className={styles[cell.column.className]}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.paginationContainer}>
        <span className={styles.pageNumber}>
          {pageIndex * state.pageSize + 1} - {pageIndex * state.pageSize + page.length} of {data.length}
        </span>
        {pageCount > 5 && (
          <Button
            //@ts-ignore
            icon="angle-double-left"
            variant="secondary"
            className={styles.paginationButton}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          />
        )}
        <Button
          icon="angle-left"
          variant="secondary"
          className={styles.paginationButton}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        />
        <Button
          icon="angle-right"
          variant="secondary"
          className={styles.paginationButton}
          onClick={() => nextPage()}
          disabled={!canNextPage}
        />
        {pageCount > 5 && (
          <Button
            //@ts-ignore
            icon="angle-double-right"
            variant="secondary"
            className={styles.paginationButton}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
        )}
      </div>
    </div>
  );
};

interface GlobalFilterProps {
  globalFilter: any;
  setGlobalFilter: (filterValue: any) => void;
}

const GlobalFilter = ({ globalFilter, setGlobalFilter }: GlobalFilterProps) => {
  const styles = useStyles(getStyles);
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className={styles.searchContainer}>
      <Input
        width={40}
        type="text"
        prefix={<Icon name="search" />}
        value={value || ''}
        onChange={({ target }) => {
          setValue((target as HTMLInputElement).value);
          onChange((target as HTMLInputElement).value);
        }}
        placeholder="Search"
      />
    </div>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    container: css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    table: css`
      width: 100%;
      margin-top: ${theme.spacing.xl};

      td {
        padding: 6px 8px;
        line-height: 30px;
        overflow-wrap: break-word;
      }

      th {
        padding: 6px 8px;
        text-align: left;
        line-height: 30px;
        white-space: nowrap;
      }

      tbody tr:nth-child(odd) {
        background: ${theme.colors.bg2};
      }
      tr {
        display: flex;
      }
    `,

    checkbox: css`
      display: flex;

      & > label {
        top: 6px;
      }
    `,
    nameColumn: css`
      width: 40%;
    `,
    searchContainer: css`
      display: flex;
      justify-content: flex-end;
      margin-top: -48px;
    `,
    paginationContainer: css`
      margin-top: ${theme.spacing.md};
      display: flex;
      justify-content: flex-end;
      align-items: center;
    `,
    paginationButton: css`
      margin-right: ${theme.spacing.md};
    `,
    pageNumber: css`
      margin-right: ${theme.spacing.md};
    `,
  };
};
